.circle {
  background-color: transparent;
  border: 4px solid var(--DarkBlue);
  border-radius: 50%;
  height: 100px;
  position: absolute;
  width: 100px;
}
.play {
  background-color: transparent;
  border-bottom: 30px solid transparent;
  border-left: 50px solid var(--DarkBlue);
  border-top: 30px solid transparent;
  left: 30%;
  position: relative;
  top: 20%;
}
