@import './GlobalStyles.css';

@font-face {
  font-family: Exo;
  src: url(./Fonts/Exo2-Regular.ttf);
}

@font-face {
  font-family: ExoBold;
  src: url(./Fonts/Exo-Bold.ttf);
}

@font-face {
  font-family: RobotoCondensed;
  src: url(./Fonts/RobotoCondensed-Italic.ttf);
}

.page-content-container {
  min-height: calc(100vh - var(--NavBarHeight) - var(--FooterHeight));
  max-width: 1000px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  background-color: white;
  width: 100%;
}

.page-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: var(--DarkBlue);
}

p,
div {
  color: var(--DarkBlue);
  font-family: sans-serif, Helvetica, Arial, Lucida;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--DarkBlue);
  font-family: 'ExoBold', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 600;
}

button {
  font-family: 'Exo';
  color: var(--DarkBlue);
}

h1 {
  font-size: var(--fs-600);
}
h2 {
  font-size: var(--fs-500);
}
h3 {
  font-size: var(--fs-400);
}
h4 {
  font-size: var(--fs-300);
}
h5 {
  font-size: var(--fs-200);
  font-family: 'Exo', Helvetica, Arial, Lucida, sans-serif;
}
h6 {
  font-size: var(--fs-100);
  font-family: 'Exo', Helvetica, Arial, Lucida, sans-serif;
}
p {
  font-size: var(--fs-200);
}
li {
  font-size: var(--fs-300);
}

.nav-bar-breadcrumb {
  /* margin-bottom: 1rem; */
}

.breadcrumb {
  display: flex;
  align-items: end;
}

.breadcrumb .breadcrumb-text {
  color: var(--DarkBlue);
  font-size: 14px;
  font-weight: 700;
  font-style: italic;
  margin-left: 15px;
  font-family: 'Exo', Helvetica, Arial, Lucida, sans-serif;
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 450px) {
  .breadcrumb {
    flex-direction: column;
    align-items: flex-start;
  }

  .breadcrumb .breadcrumb-text {
    margin-left: 22px;
  }
}

@media (max-width: 768px) {
  .nav-bar-breadcrumb {
    flex-direction: column;
    align-items: flex-start !important;
    /* margin-bottom: 1rem; */
  }

  .nav-bar-breadcrumb h1 {
    font-size: var(--fs-500);
    margin-bottom: 0.5rem;
  }

  .nav-bar-breadcrumb button {
    margin-left: 1.5rem;
    margin-right: 0 !important;
  }
}

@media (max-width: 1024px) {
  .secondary-header {
    padding-left: 25px !important;
    padding-right: 25px ;
  }
}
