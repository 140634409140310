@import '../GlobalStyles.css';

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.inner-button {
  background-image: linear-gradient(
    180deg,
    rgb(126 223 255 / 85%) 0%,
    rgba(10, 152, 199, 9) 100%
  );
  width: 300px;
  height: 225px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  transition:
    transform 0.2s ease,
    background-image 0 ease;

  &:hover {
    background-color: rgba(141, 196, 219, 0.7);
    background-image: none;
  }
}

.outer-systems-button {
  background-image: url('https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/home/Systems_Icon_large.png');
  width: 300px;
  height: 225px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
  margin: 10px;
}

@media (max-width: 774px) {
  .outer-systems-button,
  .outer-support-button,
  .inner-button,
  .outer-calculate-button {
    width: 275px !important;
    height: 175px !important;
  }
}

.outer-calculate-button {
  background-image: url('https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/home/Calculator_Icon_large.png');
  width: 300px;
  height: 225px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
  margin: 10px;
}

.outer-support-button {
  background-image: url('https://rss-web-images.s3.us-east-2.amazonaws.com/images/jet-thruster/home/Support_Icon_large.png');
  width: 300px;
  height: 225px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
  margin: 10px;
}

.button-description {
  margin-block-start: 0;
  color: white;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid white;
}

.button-title {
  margin-block-start: 0;
  color: white;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid white;
}

.home-page-hr {
  display: none;
}

.page-slideshow-container {
  display: flex;
  text-align: center;
}

.page-slideshow-caption {
  padding: 5px;
  text-align: center;
}

.page-option-links-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.8rem;
  margin-top: 1rem;
}

.page-optionlink {
  all: unset;
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  transition:
    color 0.2s ease,
    transform 0.2s ease;
}

.page-optionlink:hover {
  cursor: pointer;
  /* transform: translateY(-2px); */
}

.page-video-container {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 85%;
  margin: 0 auto;
}

.page-video {
  align-items: center;
  display: flex;
  flex: 2;
  justify-content: center;
  max-width: 640px;
  min-width: 250px;
  border: 5px solid var(--DarkBlue);
}

.video-title-section {
  text-align: left;
  width: 100%;
  border-top: 10px solid var(--CancelRed);
}

.video-title-section h1 {
  font-size: 56px;
}

.video-title-section small {
  font-size: 26px !important;
}

@media (max-width: 575px) {
  .video-title-section h1 {
    font-size: 32px;
  }

  .video-title-section small {
    font-size: 16px !important;
  }
}

.page-video-description {
  align-items: flex-start;
  color: var(--DarkBlue);
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  max-width: 250px;
  padding: 2rem;
  @media (max-width: 575px) {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 0rem;
    padding-top: 0rem;
  }
}

.page-video-description > h1 {
  height: 25px;
  white-space: nowrap;
}

.page-video-link-button {
  align-items: center;
  background-image: linear-gradient(180deg, #03afe0 0%, #1e7ce8 100%);
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  transition: transform 0.2s ease;
  width: 150px;
  color: white;
}

.page-video-link-button:hover {
  transform: translateY(-2px);
}

.page-video-link-button:active {
  transform: scale(0.95);
}

.page-comparison-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.page-comparison-image-container {
  align-items: center;
  display: 'flex';
  justify-content: 'center';
  overflow: hidden;
  text-align: center;
}

.page-comparison-image {
  height: auto;
  max-width: 500px;
  min-width: 300px;
  width: auto;
}

.page-comparisons {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.page-comparison {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-width: 275px;
}

.page-comparison-vs {
  position: relative;
  right: 65px;
  width: 50px !important;
}

.page-comparison > h3 {
  white-space: nowrap;
}

.page-comparison > ul {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}
.page-comparison > ul > li {
  height: fit-content;
  list-style-type: none;
  margin: 10px 0;
  white-space: pre-wrap;
}
.page-comparison > ul > .check::before {
  color: rgb(0, 255, 0);
  content: '\2713  ';
  font-weight: 900;
}
.page-comparison > ul > .x::before {
  color: red;
  content: 'X ';
  font-weight: 900;
}
.page-testimonials-container {
  /* align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  text-align: center; */

  /* align-items: center; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* padding-left: 2rem; */
  /* padding-right: 2rem; */
  padding-bottom: 2rem;
  text-align: center;
  /* border: 1px solid red; */
  width: 100%;
}

.testimonials-slideshow-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

@media (max-width: 692px) {
  .page-comparison {
    align-items: center;
    margin-bottom: 2rem;
    width: 100%;
  }
  .page-comparison-vs {
    left: 0px;
    position: relative;
    top: 3px;
  }
}

@media (max-width: 650px) {
  .home-page-hr {
    all: unset;
    border: 1px solid var(--DarkBlue);
    display: block;
    margin: 10px auto;
    width: 50%;
  }
}

@media (max-width: 375px) {
  .page-comparison > ul > li {
    font-size: var(--fs-200);
    height: fit-content;
    list-style-type: none;
    white-space: pre-wrap;
  }
  .page-video-container {
    margin: 0px;
    padding: 0px;
    width: 100%;
  }
  .page-video-description {
    margin-bottom: 25px;
    padding: 10px;
    width: 275px;
    align-items: center;
  }
}

@media (max-height: 750px) {
  .page-slideshow-caption {
    padding: 0px;
    text-align: center;
    margin-block-end: 0px;
    margin-block-start: 10px;
  }
}

@media (max-width: 870px) {
  #pricing-table {
  }

  #pricing-table tr td {
    font-size: 14px;
  }

  #pricing-table tr td span {
    margin-right: 4px !important;
    /* float: inline-start !important; */
    margin-top: 0px !important;
  }

  #pricing-table .pricing-table-empty-box {
    width: 5% !important;
  }
}
