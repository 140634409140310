@import '../../GlobalStyles.css';

.input-field{
    margin-top: 5px;
    margin-bottom: 5px;
}

.submit-button{
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    color: white;
    background-color: var(--DarkBlue);
}

.submit-button:hover{
    color: white;
    transform: translateY(-2px);
    background-color: var(--LightBlue);
}

.cancel-button{
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    color: white;
    background-color: var(--CancelRed);
}

.cancel-button:hover{
    color: white;
    transform: translateY(-2px);
    background-color: var(--CancelRedHovered);
}

.upload-image{
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    color: white;
    background-color: var(--DarkBlue);
}


.upload-image:hover{
    color: white;
    background-color: var(--LightBlue);
    transform: translateY(-2px);
}