@import '../GlobalStyles.css';

/* Normal NavBar */
.footer-container {
  display: flex;
  flex-direction: column;
  height: var(--FooterHeight);
}
.content-section {
  display: flex;
  flex-direction: row;
  background-color: rgb(231, 231, 231);
  flex: 4;
  color: #202020 !important;
  font-size: 0.8rem;
}
.left-content {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  flex: 2;
  white-space: nowrap;
}
.middle-content {
  display: flex;
  flex-direction: column;
  flex: 5;
  align-items: center;
  padding-top: 45px;
  justify-content: space-between;
}
.right-content {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  flex: 2;
  align-items: flex-end;
  padding-top: 25px;
}

.certified-content {
  display: flex;
  flex-direction: row;
  font-size: 0.75rem;
  align-items: flex-start;
}

.certified-paragraph {
  padding-left: 10px;
  text-align: center;
  color: #202020;
}

.footer-nav-item {
  color: var(--black);
  font-size: 1rem;
  margin: 0 10px; /* Adjust margin for spacing */
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  transition: 0.3s ease-in-out;
}

.footer-nav-item:hover {
  /* color: var(--LightBlue); */
  font-size: 1rem;
  text-decoration: none;
  opacity: 0.7;
}

.footer-nav-item:active {
  color: var(--WhiteBlue);
  font-size: 1rem;
  text-decoration: underline;
}

.social-icon {
  margin: 5px;
  color: var(--WhiteBlue);
  text-decoration: none;
}

.social-icon:hover {
  color: var(--LightBlue);
}

.social-icon-small {
  margin: 15px;
  color: var(--black);
  text-decoration: none;
  font-size: 1rem;
}

.social-icon-small:hover {
  color: var(--LightBlue);
}

.content-section-small {
  display: flex;
  flex-direction: column;
  background-color: rgb(231, 231, 231);
  flex: 4;
  color: white;
  font-size: 0.8rem;
}

.social-section-small {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  padding: 10px;
  justify-content: center;
}

.certified-content-small {
  display: flex;
  flex-direction: row;
  flex: 2;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.contact-info,
.address {
  color: #202020;
  font-size: 0.75rem;
}
