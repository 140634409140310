@import '../GlobalStyles.css';
@font-face {
  font-family: Exo;
  src: url(.././Fonts/Exo2-Regular.ttf);
}

/* Normal NavBar */
.nav-bar-container {
  display: flex;
  justify-content: center;
  height: var(--NavBarHeight);
  background-color: #f1f1f1;
  border-bottom: 2px solid #ddd;
  margin-bottom: 45px;
  @media (max-width: 885px) {
    justify-content: space-between;
  }
}

.nav-bar-container-home-page {
  display: flex;
  justify-content: center;
  height: var(--NavBarHeight);
  background-color: #1c183d;
  border-bottom: 2px solid #1c183d;
  @media (max-width: 885px) {
    justify-content: space-between;
  }
}

.nav-bar-container-small {
  display: flex;
  justify-content: center;
  height: var(--NavBarHeight);
  background-color: #f1f1f1;
  border-bottom: 2px solid #ddd;

  @media (max-width: 961px) {
    justify-content: space-between;
  }
}

.nav-bar-icon {
  margin-left: 10px;
  margin-top: 10px;
  z-index: 5;
}

.nav-bar-button {
  margin: 10px;
  padding-right: 25px;
}

.nav-options-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1; /* Allow the options to take up the remaining space */
  justify-content: center; /* Center the options horizontally */
}
.header-options-container a {
  text-decoration: none !important;
}

.header-options-container a:hover {
  opacity: 0.7;
  transition: 0.3s;
}

.header-options-container a b {
  color: white;
  font-weight: 400;
}

.header-options-container .nav-item-selected b {
  font-weight: bold;
}

.nav-item {
  color: var(--DarkBlue);
  font-size: 1rem;
  padding: 0 10px; /* Adjust margin for spacing */
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  font-family: 'Exo', Helvetica, Arial, Lucida, sans-serif;
}

.nav-item:hover {
  color: var(--LightBlue);
  font-size: 1rem;
  text-decoration: underline;
}

.nav-item:active {
  color: var(--WhiteBlue);
  font-size: 1rem;
  text-decoration: underline;
}

@media (min-width: 1400px) {
  .nav-item img {
    width: 95px !important;
  }
}

@media (min-width: 1500px) {
  .nav-item img {
    width: 125px !important;
  }
}

/* Small NavBar */
.drawer-icon {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding-right: 35px;
}

.drawer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.drawer-content {
  background-color: #f1f1f1;
  border-bottom: 2px solid #ddd;
  display: flex;
  flex-direction: column;
}

.drawer-nav-item {
  color: var(--DarkBlue);
  font-size: 1rem;
  user-select: none;
  padding: 10px;
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
  text-decoration: none;
  font-family: 'Exo', Helvetica, Arial, Lucida, sans-serif;
}

.drawer-nav-item:hover {
  color: var(--LightBlue);
  font-size: 1rem;
  text-decoration: underline;
}

.drawer-nav-item:active {
  color: var(--WhiteBlue);
  font-size: 1rem;
  text-decoration: underline;
}

.get-quote-button {
  display: inline-block;
  color: white;
  background-image: linear-gradient(180deg, #03afe0 0%, #1e7ce8 100%);
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: transform 0.2s ease, background-image 0.02 ease;
  white-space: nowrap;
  height: 40px;
  /* width: 140px; */
}

.get-quote-button:hover {
  transform: translateY(-2px);
  background-image: linear-gradient(
    180deg,
    rgba(0, 179, 239, 0.5) 0%,
    #0079ad 100%
  );
}

.get-quote-button-small {
  display: inline-block;
  padding: 10px 35px;
  font-size: 16px;
  color: var(--WhiteBlue);
  background-color: var(--DarkBlue);
  border: none;
  border-radius: 40px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-top: 25px;
  margin-bottom: 25px;
}

.get-quote-button-small:hover {
  background-color: var(--LightBlue);
  transform: translateY(-2px);
}
.nav-item-selected {
  color: var(--LightBlue);
}

.nav-bar-get-quote {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: 15px;
}
