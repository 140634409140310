.placement-button {
  padding: 10px 20px;
  font-size: 16px;
  color: var(--DarkBlue);
  border: none;
  width: 8rem;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.2s ease;
  white-space: nowrap;
  background-color: white;
  text-align: left;
  border-top: 1px dashed grey;
}

.placement-button-disabled {
  padding: 10px 20px;
  font-size: 16px;
  color: var(--DarkBlue);
  border: none;
  width: 8rem;
  transition:
    background-color 0.3s ease,
    transform 0.2s ease;
  white-space: nowrap;
  background-color: white;
  text-align: left;
  border-top: 1px dashed grey;
  text-decoration: line-through;
}

.placement-button:last-child {
  border-bottom: 1px dashed grey;
}

.get-quote-button {
  display: inline-block;
  color: white;
  background-image: linear-gradient(180deg, #03afe0 0%, #1e7ce8 100%);
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition:
    transform 0.2s ease,
    background-image 0.02 ease;
  white-space: nowrap;
  height: 40px;
  /* width: 140px; */
}

.calculate-button:hover,
.placement-button:hover {
  color: var(--LightBlue);
  transform: translateY(-2px);
}

.placement-button-clicked {
  padding: 10px 20px;
  font-size: 16px;
  color: #5296ff;
  border: none;
  width: 8rem;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.2s ease;
  white-space: nowrap;
  background-color: white;
  text-align: left;
  font-weight: bold;
  border-top: 1px dashed grey;
}

.placement-button-clicked:last-child {
  border-bottom: 1px dashed grey;
}

/* .placement-button-clicked::after{
    content: "›"; 
    margin-left: 1em; 
} */

.bounce-on {
  transition:
    background-color 0.3s ease,
    transform 0.2s ease;
  transform: translateY(-20px);
}

.bounce-off2 {
  transition:
    background-color 0.3s ease,
    transform 0.2s ease;
}

.calculate-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 1rem;
  color: var(--WhiteBlue);
  background-color: var(--DarkBlue);
  border: none;
  border-radius: 40px;
  width: 9rem;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.2s ease;
  white-space: nowrap;
  font-weight: bolder;
}

.jt-option {
  padding: 10px 20px;
  font-size: 16px;
  color: var(--DarkBlue);
  border: none;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.2s ease;
  white-space: nowrap;
  background-color: white;
  text-align: left;
  border-left: 1px dashed grey;
}

.jt-option-active {
  padding: 10px 20px;
  font-size: 16px;
  color: var(--DarkBlue);
  border: none;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.2s ease;
  white-space: nowrap;
  background-color: white;
  text-align: left;
  border-left: 1px dashed grey;
  color: #5296ff;
  font-weight: bold;
}

.jt-option-first {
  padding: 10px 20px;
  font-size: 16px;
  color: var(--DarkBlue);
  border: none;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.2s ease;
  white-space: nowrap;
  background-color: white;
  text-align: left;
}

.jt-option-first-active {
  padding: 10px 20px;
  font-size: 16px;
  color: var(--DarkBlue);
  border: none;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.2s ease;
  white-space: nowrap;
  background-color: white;
  text-align: left;
  color: #5296ff;
  font-weight: bold;
}

.jt-option-first:hover,
.jt-option:hover,
.component-info-icon:hover {
  color: var(--LightBlue);
  transform: translateY(-2px);
}

.jt-option-selection {
  font-size: 26pt;
  color: var(--DarkBlue);
  font-weight: bolder;
  transition:
    background-color 0.3s ease,
    transform 0.2s ease;
  user-select: none;
  margin-left: 0.5rem;
  width: 6rem;
}

.info-table-row {
  border: 3px solid var(--DarkBlue);
}

.info-table-ending-row {
  background-color: var(--DarkBlue);
  color: white;
}

.info-table-ending-cell {
  color: white;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.system-builder-image-thumbnails {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.system-builder-thumbnail {
  padding: 3px;
}
.system-builder-thumbnail-active {
  border: 3px solid var(--LightBlue);
  padding: 3px;
}
.system-builder-thumbnail:hover {
  cursor: pointer;
}

.component-info-icon {
  transition:
    color 0.3s ease,
    transform 0.2s ease;
  margin-left: 10px;
}

.additional-component-options {
  color: white;
  margin-left: 2rem;
  margin-bottom: 0.5rem;
}
@media (max-width: 400px) {
  .additional-component-options {
    color: white;
    margin-left: 1rem;
    margin-bottom: 0.5rem;
  }
}

.additional-component-headers {
  color: white;
  margin-bottom: 0.5rem;
}

.default-option-selection {
  color: var(--WhiteBlue);
}

.options-containing-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.option-div {
  margin-left: 2rem;
}

.system-builder-image-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 3;
  margin-left: 0px;
  height: 272px;
}

.system-builder-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-left: 25px;
  margin-right: 25px;
}

@media (max-width: 767px) {
  .system-builder-image-container {
    flex-direction: column;
    height: auto;
  }

  .system-builder-image-container {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 767px) {

  .breadcrumb-buttons {
    margin-bottom: 10px;
  }
}


