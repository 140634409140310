.multi-slideshow {
  /* align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center; */
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 10px 100px;
  /* border: 1px solid rebeccapurple; */
  width: 665px;
}
.multi-slideshow-slide {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  /* margin-left: 10px;
  margin-right: 10px; */
  margin-top: 10px;
}

.multi-slideshow-text {
  align-items: center;
  color: var(--DarkBlue);
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.multi-slideshow-image {
  width: 100%;
}

@media (max-width: 998px) {
  .multi-slideshow-image {
    width: 245px;
  }

  .multi-slideshow-text {
    width: 245px;
  }
}
@media (max-width: 780px) {
  .multi-slideshow-image {
    width: 175px;
  }

  .multi-slideshow-text {
    width: 175px;
  }
}
