.slideshow-navigation-button {
  all: unset;
  color: white;
  font-size: 3rem;
  margin: 0.5rem;
  position: relative;
}
.slideshow-navigation-button:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: transform 250ms ease-in-out;
}
.slideshow-navigation-button:active {
  transform: scale(1);
}
.slideshow-navigation-button-container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
}

.slide-one{
  margin-left:40%;
  margin-right: 0%;
  max-width: 325px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  @media (max-width: 700px) {
    margin-left:20%;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
}

.slide-two{
  margin-left:0%;
  margin-right: 40%;
  max-width: 325px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  @media (max-width: 700px) {
    margin-right:8%;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
}

.slide-three{
  margin-left:40%;
  margin-right: 0%;
  max-width: 325px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  @media (max-width: 700px) {
    margin-left:10%;
    margin-top: 20px;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.slide-four{
  margin-left:0%;
  margin-right: 40%;
  max-width: 325px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  @media (max-width: 700px) {
    margin-left:18%;
    margin-right: 0%;
    margin-top: 20px;
    align-items: flex-start;
    justify-content: flex-start;
  }  
}
