:root {
    --DarkBlue: #292a59;
    --DarkBlueRGB: rgb(41, 42, 89);
    --WhiteBlue: #FAFDFF;
    --LightBlue: #0069B2;
    --LightBlueBackground: #D8E7F2;
    --FooterHeight: 250px;
    --NavBarHeight: 60px;
    --CancelRed: hsl(345, 96%, 42%);
    --CancelRedHovered: hwb(345 0% 42%);

    /* Using same convention as font-weight */
    --fs-600: 2.1rem;
    --fs-500: 1.5rem;
    --fs-400: 1.25rem;
    --fs-300: 1.15rem;
    --fs-200: 1rem;
    --fs-100: .85rem;

    font-size: 1.25rem;
  }

@media (max-width: 768px) {
  :root {
    --fs-600: 1.85rem;
    --fs-500: 1.4rem;
    --fs-400: 1.20rem;
    --fs-300: 1.10rem;
    --fs-200: .9rem;
    --fs-100: .75rem;
  }
}

@media (max-width: 480px) {
  :root {
    --fs-600: 1.6rem;
    --fs-500: 1.35rem;
    --fs-400: 1.15rem;
    --fs-300: 1rem;
    --fs-200: .85rem;
    --fs-100: .70rem;
  }
}

@media (max-width: 320px) {
  :root {
    --fs-600: 1.4rem;
    --fs-500: 1.25rem;
    --fs-400: 1.15rem;
    --fs-300: 1.05rem;
    --fs-250: .95rem;
    --fs-200: .75rem;
    --fs-100: .65rem;
  }
}