.slideshow-container {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
}
.slideshow-image {
  max-height: 350px;
  transition: opacity 1000ms ease-in-out;
  width: 100%;

  object-fit: "contain";
}
.slideshow-title {
  background: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: 4.5vw;
  font-weight: 100;
  margin: 0;
  max-height: 300px;
  object-fit: contain;
  padding: 2vw;
  position: absolute;
  text-align: center;
  top: 0%;
  transition: opacity 1000ms ease-in-out;
  white-space: nowrap;
}
@media (min-width: 768px) {
  .slideshow-title {
    font-size: 2.5rem;
  }
}
@media (max-width: 768px) {
  .slideshow-title {
    font-size: 1.5rem;
  }
}
@media (max-width: 480px) {
  .slideshow-title {
    font-size: 1rem;
  }
}
@media (max-width: 320px) {
  .slideshow-title {
    font-size: 0.65rem;
  }
}
