.component-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  min-height: 400px;
  padding: 25px;
}
.component-container:nth-child(odd) {
  background-color: var(--DarkBlue);
}
.component-container:nth-child(odd) > .component-text-container > * {
  color: white;
}
.component-container:nth-child(even) > .component-text-container > * {
  /* Due to even containers looking more bold by default */
  font-weight: 550;
}
.component-image-container {
  height: 300px;
  width: clamp(300px, 50%, 600px);
}
.component-image-container img {
  height: 100%;
  object-fit: contain;
  width: 100%;
  text-align: center;
}
.component-text-container {
  width: clamp(300px, 50%, 600px);
}

#maintenance > div > p {
  margin-right: 25px;
}

@media (max-width: 650px) {
  #maintenance > div > p {
    margin-right: 0;
  }
}
