@import "../GlobalStyles.css";
.calculator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.calculator-results {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.calculator-results > * {
  margin: 0;
}
.calculator-section {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 5px;
  width: 100%;
  height: 150px;
  justify-content: space-between;
  align-items: center;
}
.calculator-inputs {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 60%;
}
.calculator-inputs > p {
  font-size: .65rem;
  margin: 2px;
  text-align: left;
}
.calculator-inputs > h5 {
    font-size: .8rem;
    margin: 2px;
}

.exact-calc-input,
.calculator-inputs > input {
  width: 100px;
  height: 35px;
  border-radius: 5px;
  border: 2px solid var(--DarkBlue);
  margin: 2px; 
}

.calculator-length-icon{
  width: 150px;
  height: 75px;
  @media(max-width: 900px){
    width: 150px;
    height: 75px;
  }
}

.calculator-height-icon{
  width: 150px;
  height: 150px;
  @media(max-width: 900px){
    width: 150px;
    height: 140px;
  }
}

.calculator-wind-icon{
  width: 150px;
  height: 150px;
  @media(max-width: 900px){
    width: 150px;
    height: 150px;
  }
}

.calculator-wind-options {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* align-items: flex-start; */
  width: 100%;
  flex: 1;
}
.calculator-wind-option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3px;
  border: 2px solid var(--DarkBlue);
  border-radius: 5px;
  padding: 5px;
  width: 100px;
  transition: all 0.25s ease-in-out;
  margin-bottom: 0px;
}
.calculator-wind-option:hover {
  transform: scale(0.95);
  cursor: pointer;
}
.calculator-wind-option.active {
  background-color: var(--DarkBlue);
}
.calculator-wind-option.active > * {
  color: white;
}
.calculator-wind-option > p {
  font-size: .60rem;
  /* text-wrap: nowrap; */
  margin: 0 1px;
}
.calculator-wind-option > h6 {
  font-size: .70rem;
  margin: 0 1px;
}
@media (max-width: 1000px){
  .calculator-wind-option > p {
    font-size: .50rem;
  }
  .calculator-wind-option > h5 {
    font-size: .60rem;
  }
  .calculator-inputs > p {
    font-size: .50rem;
  }
  .calculator-inputs > h5 {
    font-size: .70rem;
  }
}
@media(max-width: 900px){
  .calculator-length, .calculator-height {
    height: 100px;
  }
  .calculator-wind {
    padding-top: 15px;
    height: 200px;
    margin-top: 10px;
  }
 
  .calculator-wind-options {
    flex-direction: column;
  }
  .calculator-wind-option {
    width: 75%;
    height: 30px;
  }
}
@media (max-width: 700px){
  .calculator-wind {
    padding-top: 15px;
    height: 110px;
    margin-top: 10px;
    
  }
  
  .calculator-wind-option > p {
    font-size: .50rem;
  }
  .calculator-length {
    margin-top: 20px;
  }
  .calculator-height {
    height: 225px;
  }
  .calculator-wind > img {
    height: 60px;
  }
  .calculator-wind-option > h5 {
    font-size: .60rem;
  }
  .calculator-inputs > p {
    font-size: .50rem;
  }
  .calculator-inputs > h5 {
    font-size: .70rem;
  }
  .calculator-section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    padding: 15px;
  }
  .calculator-inputs {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .calculator-wind-options {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .calculator-wind-option {
    width: 80px;
    height: 40px;
  }
}

.calculator-link{
  text-decoration: none;
  color: inherit;
  transition: color 0.3s ease, transform 0.2s ease;
}

.calculator-link:hover{
  color: var(--LightBlue);
  transform: translateY(5px);
  cursor: pointer;
}